<template>
  <el-form
    ref="loginForm"
    :model="state.form"
    :rules="state.rules"
    @submit.prevent
    @keydown.enter="submitForm(loginForm)"
  >
    <el-form-item prop="userName" label="商户号：">
      <el-input
        v-model="state.form.userName"
        placeholder="请输入商户号"
        :maxlength="11"
        :formatter="(value: string) => `${value}`.replace(/[^0-9-]+/, '')"
        :parser="(value: string) => value.replace(/[^0-9-]+/, '')"
      >
      </el-input>
    </el-form-item>
    <el-form-item prop="password" label="密 码：">
      <el-input v-model="state.form.password" placeholder="请输入密码"> </el-input>
    </el-form-item>
    <span class="tips">注：账号密码错误3次将被无限期锁死</span>
    <el-form-item>
      <el-button @click="submitForm(loginForm)" type="primary" style="width: 100%; margin-top: 10px"
        >登录</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script lang="ts" setup>
  import { ElMessage } from 'element-plus';
  import basics from '@/libs/basics';
  import http from '@/libs/service/http.js';
  import type { FormInstance } from 'element-plus';
  import { Auth } from '@/libs/requestAddress';
  const emit = defineEmits(['on-success-valid']);
  let timer: number | null = null;
  const state = reactive({
    form: {
      userName: '',
      password: '',
    },
    rules: {
      userName: [
        {
          message: '商户号不能为空',
          required: true,
        },
      ],
      password: [
        {
          message: '密码不能为空',
          required: true,
        },
      ],
    },
  });

  const loginForm = ref<FormInstance>();

  const submitForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        let user = {
          phone: state.form.userName,
          password: state.form.password, // smsCode
        };
        emit('on-success-valid', user);
      }
    });
  };
</script>
<style lang="less" scoped>
  :deep(.el-form-item__label) {
    width: 80px;
  }
  .tips {
    color: #f56c6c;
  }
</style>
