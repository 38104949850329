interface page {
  [key: string]: any;
}

export default {
  Main: () => import('@/components/main/main.vue'),
  home: () => import('@/views/home/home.vue'),

  /* 渠道管理 */
  channelList: () => import('@/views/channelManagement/channelList/channelList.vue'), // 渠道列表
  channelStatistic: () => import('@/views/channelManagement/channelStatistic/channelStatistic.vue'), // 渠道数据统计
  channelBalance: () => import('@/views/channelManagement/channelBalance/channelBalance.vue'), // 渠道余额
  chanelSettlementDetails: () =>
    import('@/views/channelManagement/chanelSettlementDetails/index.vue'), // 渠道结算详情
  /* 平台管理 */
  platformList: () => import('@/views/platformManagement/platformList/platformList.vue'), // 平台列表
  platformStatistic: () =>
    import('@/views/platformManagement/platformStatistic/platformStatistic.vue'), // 平台数据统计
  platformAccount: () => import('@/views/platformManagement/platformAccount/platformAccount.vue'), // 平台账户
  platformRecords: () => import('@/views/platformManagement/platformRecords/platformRecords.vue'), // 平台消耗记录
  platformRecharge: () =>
    import('@/views/platformManagement/platformRecharge/platformRecharge.vue'), // 平台充值记录

  /* 业务管理 */
  hitDetail: () => import('@/views/businessManagement/hitDetail/hitDetail.vue'), // 撞库明细
  applyDetail: () => import('@/views/businessManagement/applyDetail/applyDetail.vue'), // 申请记录明细
  onlineLoanOrderList: () => import('@/views/businessManagement/onlineLoanOrderList/index.vue'), // 网贷订单列表
  onlineDetailedList: () => import('@/views/businessManagement/onlineDetailedList/index.vue'), // 网贷撞库明细表

  /* 机构管理 */
  institutionList: () =>
    import('@/views/institutionManagement/institutionList/institutionList.vue'), // 机构列表
  institutionProductList: () =>
    import('@/views/institutionManagement/institutionProductList/institutionProductList.vue'), // 机构产品列表
  institutionDataStatistic: () =>
    import('@/views/institutionManagement/institutionDataStatistic/institutionDataStatistic.vue'), // 机构数据统计
  institutionProductStatistic: () =>
    import(
      '@/views/institutionManagement/institutionProductStatistic/institutionProductStatistic.vue'
    ), // 产品数据统计
  institutionRechargeRecord: () =>
    import('@/views/institutionManagement/institutionRechargeRecord/institutionRechargeRecord.vue'), // 充值记录

  /* 数据面板 */
  completeFlowSchemeStatistic: () =>
    import('@/views/dataManagement/completeFlowSchemeStatistic/completeFlowSchemeStatistic.vue'), // 全流程数据统计

  /* 系统管理 */
  adminUser: () => import('@/views/systemManagement/user/user.vue'), // 用户管理
  adminRole: () => import('@/views/systemManagement/role/role.vue'), // 角色管理
  sysModule: () => import('@/views/systemManagement/sysModule/sysModule.vue'), // 菜单管理
  parameterSettings: () =>
    import('@/views/systemManagement/parameterSettings/parameterSettings.vue'), // 参数配置管理

  /* 渠道面板 */
  channelPanel: () => import('@/views/channelPanelManagement/channelPanel/index.vue'), // 渠道面板
  settlementVouchers: () => import('@/views/channelPanelManagement/settlementVouchers/index.vue'), // 结算凭证

  /* 落地页管理 */
  landingPageList: () =>
    import('@/views/landingPageManagement/landingPageList/landingPageList.vue'), // 落地页列表
  landingPageRoute: () =>
    import('@/views/landingPageManagement/landingPageRoute/landingPageRoute.vue'), // 落地页路由

  /* 产品管理 */
  productList: () => import('@/views/productManagement/productList/productList.vue'), // 产品列表
  productDataPanel: () => import('@/views/productManagement/productDataPanel/productDataPanel.vue'), // 产品数据面板
  productBrandingList: () =>
    import('@/views/productManagement/productBrandingList/productBrandingList.vue'), // 产品品牌列表

  /* 渠道管理 */
  marketingChannelList: () =>
    import('@/views/markeChannelManagement/marketingChannelList/marketingChannelList.vue'), // 营销渠道列表
  marketingchannelStatistic: () =>
    import(
      '@/views/markeChannelManagement/marketingchannelStatistic/marketingchannelStatistic.vue'
    ), // 营销渠道数据统计

  /* 开发助手 */
  channelParamEncryption: () =>
    import('@/views/developmentAssistant/channelParamEncryption/paramEncryption.vue'), // 渠道参数加解密
  adminParamEncryption: () =>
    import('@/views/developmentAssistant/adminParamEncryption/paramEncryption.vue'), // 渠道参数加解密

  /* 营销短信管理 */
  smsTemplate: () => import('@/views/markeSmsManagement/smsTemplate/SmsTemplate.vue'), // 短信模版
  distributionPlan: () =>
    import('@/views/markeSmsManagement/distributionPlan/DistributionPlan.vue'), // 分发计划
} as page;
