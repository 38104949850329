import api from './api.js';

const TimeArr: any[] = [];
for (let i = 0; i < 24; i++) {
  TimeArr.push({ text: i + '时', value: `${i}` });
}
const TimeNewArr: any[] = [];
for (let i = 0; i < 24; i++) {
  const paddedHour = i.toString().padStart(2, '0'); // 这里使用 padStart 来保证小时数始终为两位
  TimeNewArr.push({ text: `${paddedHour}时`, value: paddedHour });
}
const adminConfig = {
  otherConfiguration: {
    apiPrefixPath: api.apiPrefixPath,
    /* 默认线上请求地址 */
    uploadExcelUrl: '/customer/uploadExcel', // excel上传地址
    pictureUploading: '/upload/img', // 图片上传地址
    picturePrefix: 'image', // 图片前缀
  },
  requestParameters: {
    // 请求参数
    request_SUCCESS: 0, // 请求成功状态
    request_FAIL: 1, // 请求失败状态
    request_TOKEN_ERROR: 401, // 登录失效
    request_SIGN_ERROR: 402, // 签名校验失败
    request_AUTH_ERROR: 403, // 访问受限
    request_RUN_EXCEPTION: -1, // 主动抛出异常（一般为参数校验失败，或流程校验不通过等）
    request_BLACK: -10, // 黑名单
    request_NOT_PERMISSION: -20, // 无权限访问
    request_TIMEOUT: -30, // 请求超时
    request_VISIT_OUT: -40, // 访问次数受限
    request_NOT_FOUND: 404, // 资源不存在
  },
  store: {
    authorCacheName: 'userLoginInfo',
  },
  componentName: {
    MyTips: 'MyTips',
    MyTitle: 'MyTitle',
    MyInput: 'MyInput',
    MySelect: 'MySelect',
    MySelectCity: 'MySelectCity',
    MySelectCityLocal: 'MySelectCityLocal',
    MyCityLocalSearch: 'MyCityLocalSearch',
    MyTreeSelect: 'MyTreeSelect',
    MyRadio: 'MyRadio',
    MyCheckbox: 'MyCheckbox',
    ImgUpload: 'ImgUpload',
    MyTime: 'MyTime',
    MyDateTime: 'MyDateTime',
    MyRadioTime: 'MyRadioTime',
    MyCustomFormItem: 'MyCustomFormItem',
    MyGuideConfig: 'MyGuideConfig',
    MyText: 'MyText',
    MyCustomAddInputInputItem: 'MyCustomAddInputInputItem',
    MyInputLinkInputItem: 'MyInputLinkInputItem',
    WarningConfig: 'WarningConfig',
  },
  table: {
    buttonType: {
      // table按钮功能
      otherEvents: 'otherEvents', // 按钮其他事件
      delete: 'Delete', // 删除功能按钮
      render: 'Render', // 自定义内容按钮
    },
  },
  commonList: {
    sex: [
      {
        text: '男',
        value: 0,
      },
      {
        text: '女',
        value: 1,
      },
    ],
    // 选择是否
    isControl: [
      {
        text: '否',
        value: 0,
      },
      {
        text: '是',
        value: 1,
      },
    ],
    // 选择是否开启短信通知
    isNotice: [
      {
        text: '关闭通知',
        value: 0,
      },
      {
        text: '开启通知',
        value: 1,
      },
    ],
    onlineStatus: [
      {
        text: '正常',
        value: 0,
      },
      {
        text: '停用',
        value: 1,
      },
    ],
    // 渠道列表-状态
    channelStatus_: [
      {
        text: '下线',
        value: 0,
      },
      {
        text: '上线',
        value: 1,
      },
    ],
    meansPayments: [
      {
        text: 'CPA',
        value: 1,
      },
      {
        text: 'CPS',
        value: 2,
      },
    ],
    isCallBack: [
      {
        text: '不回调',
        value: 0,
      },
      {
        text: '我方文档回调',
        value: 1,
      },
      {
        text: '渠道文档回调',
        value: 2,
      },
    ],
    hoursList: TimeArr,
    weekList: [
      {
        text: '周一',
        value: 1,
      },
      {
        text: '周二',
        value: 2,
      },
      {
        text: '周三',
        value: 3,
      },
      {
        text: '周四',
        value: 4,
      },
      {
        text: '周五',
        value: 5,
      },
      {
        text: '周六',
        value: 6,
      },
      {
        text: '周天',
        value: 7,
      },
    ],
    limitCityType: [
      {
        text: '不限',
        value: 0,
      },
      {
        text: '准入',
        value: 1,
      },
      {
        text: '禁入',
        value: 2,
      },
    ],
    platformType: [
      {
        text: '半流程',
        value: 1,
      },
      {
        text: '全流程',
        value: 2,
      },
    ],
    settlementPrice: [
      {
        text: '固定价格',
        value: 1,
      },
      {
        text: 'API回调价格',
        value: 2,
      },
    ],
    applyStatus: [
      {
        text: '待申请',
        value: 0,
      },
      {
        text: '申请处理中',
        value: 1,
      },
      {
        text: '申请成功',
        value: 2,
      },
      {
        text: '申请失败',
        value: 3,
      },
    ],
    resultStatus: [
      {
        text: '撞库成功',
        value: 0,
      },
      {
        text: '撞库失败',
        value: 1,
      },
      {
        text: '撞库超时',
        value: 2,
      },
    ],
    cooperateStatus: [
      {
        text: '存续',
        value: 1,
      },
      {
        text: '终止',
        value: 2,
      },
    ],
    settlementType: [
      {
        text: '预付',
        value: 1,
      },
      {
        text: '月付',
        value: 2,
      },
    ],
    writeOffStatus: [
      {
        text: '未核销',
        value: 0,
      },
      {
        text: '已核销',
        value: 1,
      },
    ],
    filterTypeList: [
      {
        text: '选中资质任意满足其一',
        value: '1',
      },
      {
        text: '选中资质任意满足其二',
        value: '2',
      },
      {
        text: '选中资质任意满足其三',
        value: '3',
      },
      {
        text: '选中资质任意满足其四',
        value: '4',
      },
      {
        text: '选中资质任意满足其五',
        value: '5',
      },
      {
        text: '选中资质任意满足其六',
        value: '6',
      },
      {
        text: '选中资质任意满足其七',
        value: '7',
      },
    ],
    channelType: [
      {
        text: '普通贷超',
        value: 0,
      },
      {
        text: '撞库注册',
        value: 1,
      },
      {
        text: '撞库联登',
        value: 2,
      },
      {
        text: '联登',
        value: 3,
      },
    ],
    settlementMethod: [
      {
        text: 'UV',
        value: 0,
      },
      {
        text: 'CPA',
        value: 1,
      },
      {
        text: 'PV',
        value: 2,
      },
    ],
    productAddress: [
      {
        text: '动态',
        value: 0,
      },
      {
        text: '静态',
        value: 1,
      },
    ],
    productInterestRateType: [
      {
        text: 'APR',
        value: 0,
      },
      {
        text: 'IRR',
        value: 1,
      },
    ],
    appType: [
      {
        text: '安卓',
        value: 0,
      },
      {
        text: 'ios',
        value: 1,
      },
      {
        text: '都展示',
        value: 2,
      },
    ],
    hoursNewList: TimeNewArr,
    loanCycle: [
      {
        text: '3',
        value: 3,
      },
      {
        text: '6',
        value: 6,
      },
      {
        text: '9',
        value: 9,
      },
      {
        text: '12',
        value: 12,
      },
      {
        text: '24',
        value: 24,
      },
      {
        text: '36',
        value: 36,
      },
      {
        text: '48',
        value: 48,
      },
      {
        text: '60',
        value: 60,
      },
      {
        text: '120',
        value: 120,
      },
      {
        text: '240',
        value: 240,
      },
      {
        text: '360',
        value: 360,
      },
    ],
    isCallBackMode: [
      {
        text: '我方文档回调',
        value: 1,
      },
      {
        text: '渠道方文档回调',
        value: 2,
      },
    ],
    orderStatus: [
      {
        text: '申请处理中',
        value: 1,
      },
      {
        text: '申请成功',
        value: 2,
      },
      {
        text: '申请失败',
        value: 3,
      },
    ],
    impactStatus_: [
      {
        text: '撞库失败',
        value: 0,
      },
      {
        text: '撞库成功',
        value: 1,
      },
    ],
    productImpactStatus: [
      {
        text: '请求异常',
        value: 0,
      },
      {
        text: '撞库成功',
        value: 1,
      },
      {
        text: '撞库失败',
        value: 2,
      },
    ],
    collisionPrice: [
      {
        text: '否',
        value: 0,
      },
      {
        text: '返回分润前价格',
        value: 1,
      },
      {
        text: '返回分润后价格',
        value: 2,
      },
    ],
    isSwitch: [
      {
        text: '关闭',
        value: 0,
      },
      {
        text: '开启',
        value: 1,
      },
    ],
    isToken: [
      {
        text: '不需要',
        value: 0,
      },
      {
        text: '需要',
        value: 1,
      },
    ],
    matchStrategy: [
      {
        text: '不撞库',
        value: 0,
      },
      {
        text: '全量撞库',
        value: 1,
      },
      {
        text: '仅撞网贷',
        value: 2,
      },
      {
        text: '仅撞优卡库',
        value: 3,
      },
      {
        text: '撞信贷+定向优卡产品库',
        value: 4,
      },
      {
        text: '仅撞海通融租',
        value: 5,
      },
    ],
    isEnableLimit: [
      {
        text: '开启',
        value: 1,
      },
      {
        text: '不开启',
        value: 0,
      },
    ],
    smsSignature: [
      {
        text: '乾小花',
        value: 1,
      },
    ],
    scene: [
      {
        text: '撞库成功未进件信贷产品的用户',
        value: 1,
      },
      {
        text: '留资信息车产为有车用户',
        value: 2,
      },
      {
        text: '优卡产品4010调用进件的用户',
        value: 3,
      },
      {
        text: '昨日登陆用户召回',
        value: 4,
      },
    ],
    sendNumber: [
      {
        text: '永久只发一次',
        value: 0,
      },
    ],
    SendResend: [
      {
        text: '永久只发一次',
        value: 0,
      },
      {
        text: '1天去重',
        value: 1,
      },
      {
        text: '2天去重',
        value: 2,
      },
      {
        text: '3天去重',
        value: 3,
      },
      {
        text: '5天去重',
        value: 5,
      },
    ],
    isEnable: [
      {
        text: '禁用',
        value: 0,
      },
      {
        text: '启用',
        value: 1,
      },
    ],
  },
};

export default adminConfig;
