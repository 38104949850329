<template>
  <div class="ChannelLogin">
    <div class="login-con">
      <div>
        <p class="title">欢迎登录</p>
        <div class="form-con">
          <ChannelLoginForm @on-success-valid="handleSubmit"></ChannelLoginForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import ChannelLoginForm from '_c/ChannelLoginForm/index.vue';
  import adminConfig from '@/libs/config.js';
  import basics from '@/libs/basics';
  import http from '@/libs/service/http.js';
  import { Auth } from '@/libs/requestAddress';

  export default {
    components: {
      ChannelLoginForm,
    },
    setup() {
      const router = useRouter();
      const handleSubmit = (user: { phone: string; password: string }) => {
        http.post(Auth.channelLogin, user).then((res) => {
          basics.Storage.setSession(adminConfig.store.authorCacheName, res);
          router.replace({
            path: '/channelPanel',
          });
        });
      };

      return {
        handleSubmit,
      };
    },
  };
</script>
<style lang="less" scoped>
  @import './ChannelLogin.less';
</style>
