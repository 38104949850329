interface regularInter {
  regular: any;
  msg: string;
}

const regular = (type: string): regularInter => {
  switch (type) {
    case 'phone': // 手机号码
      return {
        regular: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
        msg: '请输入正确的电话号码',
      };
    case 'ID': // 身份证
      return {
        regular: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
        msg: '请输入正确的身份证号',
      };
    case 'pwd': // 密码以字母开头，长度在6~16之间，只能包含字母、数字和下划线
      return {
        regular: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/,
        msg: '请输入正确的密码',
      };
    case 'email': // 邮箱
      return {
        regular: /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/,
        msg: '请输入正确的邮箱',
      };
    case 'URL': // 网址
      return {
        regular: /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/,
        msg: '请输入正确的网址',
      };
    case 'IP': // IP
      return {
        regular: /((?:(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d)\\.){3}(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d))/,
        msg: '请输入正确的IP',
      };
    case 'number': // 数字
      return {
        regular: /^(\d)*$/,
        msg: '请输入正整数',
      };
    case 'numberDouble': // 数字正负
      return {
        regular: /^(-)?(\d)*$/,
        msg: '请输入正整数',
      };
    case 'fullName': // 姓名
      return {
        regular:
          /^[\u00B7\u3007\u3400-\u9FFF\uE000-\uF8FF\uF900-\uFAFF\u{20000}-\u{2FFFF}\u{30000}-\u{3FFFF}]+$/u,
        msg: '请输入正确的姓名',
      };
    case 'noZero': // 不为0
      return {
        regular: /^[1-9]\d*$/,
        msg: '内容不能等于0',
      };
    case 'decimal':
      return {
        regular: /^(-?\d+)(\.\d+)?$/,
        msg: '请输入正确的数字',
      };
    case 'ntf':
      return {
        // eslint-disable-next-line no-control-regex
        regular: /^[\u4e00-\u9fffa-zA-Z0-9\uFF00-\uFFFF\u0000-\u00FF]{1,25}$/,
        msg: '产品描述不能大于25个字',
      };
    case 'noh':
      return {
        // eslint-disable-next-line no-control-regex
        regular: /^[\u4e00-\u9fffa-zA-Z0-9\uFF00-\uFFFF\u0000-\u00FF]{1,100}$/,
        msg: '产品描述不能大于100个字',
      };
    case 'nofive':
      return {
        // eslint-disable-next-line no-control-regex
        regular: /^[\u4e00-\u9fffa-zA-Z0-9\uFF00-\uFFFF\u0000-\u00FF]{1,5}$/,
        msg: '不能大于5个字',
      };
    case 'nosix':
      return {
        // eslint-disable-next-line no-control-regex
        regular: /^[\u4e00-\u9fffa-zA-Z0-9\uFF00-\uFFFF\u0000-\u00FF]{1,6}$/,
        msg: '不能大于6个字',
      };
    case 'noten':
      return {
        // eslint-disable-next-line no-control-regex
        regular: /^[\u4e00-\u9fffa-zA-Z0-9\uFF00-\uFFFF\u0000-\u00FF]{1,10}$/,
        msg: '不能大于10个字',
      };
    case 'notenfive':
      return {
        // eslint-disable-next-line no-control-regex
        regular: /^[\u4e00-\u9fffa-zA-Z0-9\uFF00-\uFFFF\u0000-\u00FF]{1,15}$/,
        msg: '不能大于15个字',
      };
    case 'nott':
      return {
        // eslint-disable-next-line no-control-regex
        regular: /^[\u4e00-\u9fffa-zA-Z0-9\uFF00-\uFFFF\u0000-\u00FF]{1,20}$/,
        msg: '不能大于20个字',
      };
    // 只能输入数字或1位小数数字
    case 'onlyNumberOrOnePointNumber':
      return {
        regular: /^(\d+.\d{1,1}|\d+)$/,
        msg: '请输入正确格式的内容',
      };
    // 只能输入数字或2位小数数字
    case 'onlyNumberOrTwoPointNumber':
      return {
        regular: /^(\d+.\d{1,2}|\d+)$/,
        msg: '请输入正确格式的内容',
      };
    // 只能输入数字或2位小数数字 可以为负数
    case 'onlyNumberOrTwoPointNumberDouble':
      return {
        regular: /^(-)?(\d+.\d{1,2}|\d+)$/,
        msg: '请输入正确格式的内容',
      };
    // 只能输入0-100之间的数字或2位小数数字
    case 'only0_100OrTwoPointNumber':
      return {
        regular: /^(\d{1,2}$)|(^\d{1,2}\.\d{1,2}$)|100$/,
        msg: '请输入正确格式的内容',
      };
    // 只能输入数字或4位小数数字
    case 'onlyNumberOrFourPointNumber':
      return {
        regular: /^(\d+.\d{1,4}|\d+)$/,
        msg: '请输入正确格式的内容',
      };
    case 'upCapital':
      return {
        regular: /^[A-Z]+$/g,
        msg: '请输入大写字母',
      };
    default:
      throw new Error(type + 'regular');
  }
};

export default regular;
