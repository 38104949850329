/* 公共接口 */
export const Common = {
  fileUpload: `upload/image`, // 图片上传
  videoUpload: `upload/video`, // 视频上传
  apkUpload: `/upload/apk`, // APK上传
  excelUpload: `/upload/excel`, // excel上传
  getCityALLData: `/channel/cityConstant/list`,
  selectMetadataField: '/selectMetadataField', // 元数据查询
};

/* 用户权限 */
export const Auth = {
  sendSms: `/login/sms.e`,
  login: `/login/login.e`,
  password: `/login/password.e`,
  dynamicPassword: `/login/dynamicPassword.e`,
  channelLogin: `/login/channel.e`,
};

/* 系统管理 */
export const System = {
  /* 系统用户 */
  sysUserPage: '/sys/user/page', // 用户列表查询 get
  sysUserInsert: '/sys/user/insert', // 用户新增 post
  sysUserUpdate: '/sys/user/update', // 用户修改 post
  sysUserDetail: '/sys/user/detail', // 用户详情查询 get
  sysUserRole: '/sys/userRole', // 修改用户角色 post  用户已有角色列表 get
  /* 角色 */
  sysRoleInsert: '/sys/role/insert', // 用户角色保存 post
  sysRoleUpdate: '/sys/role/update', // 用户角色新增 post
  sysRoleDelete: '/sys/role/delete', // 角色 删除 post {id}
  sysRole: '/sys/role', // 基础权限树获取 get
  sysRoleAndMenu: '/sys/roleandmenu', // 添加角色-菜单关联关系 post
  sysRoleDetail: '/sys/role/detail', // 角色详情获取 get
  /* 菜单 */
  sysMenuInsert: '/sys/menu/insert', // 菜单保存 post
  sysMenuUpdate: '/sys/menu/update', // 菜单修改 post
  sysMenuList: '/sys/menu/list', // 查询菜单树（有roleId时会携带是否已选择菜单的结果） get
  sysSelectUserMenus: '/sys/selectUserMenus', // 获取用户已有菜单树 get
  sysMenu: '/sys/menu', // 菜单删除 post {id}
  sysMenuDetail: '/sys/menu/detail', // 查询菜单详情 get
  sysRoleMenuList: '/sys/roleMenu/list', // 查询角色已有菜单列表 get

  getCommissioner: 'sys/user/getCommissioner',
  userInfo: 'sys/user/info',
  /* 参数配置 */
  sysParamPage: '/sys/param/page', // 参数列表
  sysParamAdd: '/sys/param/add', // 新增参数
  sysParamUpdate: '/sys/param/update', // 修改参数
};

/* 渠道管理 */
// 渠道列表
export const Channel = {
  pageList: '/channel/pageList',
  list: '/channel/list',
  detail: '/channel/detail',
  save: '/channel/save',
  update: '/channel/update',
  getConfig: '/channel/get_config',
};
// 渠道数据统计
export const CountChannel = {
  count: '/count/channel/count',
  export: '/count/channel/export',
};
// 渠道余额
export const ChannelBalance = {
  pageList: '/channel/balance/info',
  detailList: '/channel/balance/oneDetails',
};
// 渠道结算明细
export const ChannelSettlement = {
  pageList: '/channel/balance/details',
  balance: '/channel/balance',
  calculate: '/channel/balance/calculate',
};

// 平台管理
export const Platform = {
  pageList: '/platform/pageList',
  detail: '/platform/detail',
  insert: '/platform/insert',
  update: '/platform/update',
  status: '/platform/status',
  allList: '/platform/allList',
};
// 平台统计
export const CountPlatform = {
  count: '/count/request/count',
  export: '/count/request/export',
};
// 撞库明细
export const UserFilter = {
  count: '/userFilter/record',
  export: '/userFilter/export',
};
// 申请记录明细
export const UserOrder = {
  count: '/userOrder/record',
  export: '/userOrder/export',
  channelList: '/userOrder/channelList',
};
// 全流程数据统计
export const FlowPath = {
  count: '/flowPath/count',
  export: '/flowPath/export',
};
// 机构列表
export const Agency = {
  list: '/agency/list',
  pageList: '/agency/pageList',
  detail: '/agency/detail',
  save: '/agency/save',
  update: '/agency/update',
  recharge: '/agency/recharge',
  dynamicCode: '/agency/dynamicCode',
  rechargePageList: '/agency/recharge/pageList',
  rechargePageAudit: '/agency/recharge/audit',
};
export const Product = {
  pageList: '/agency/platform/pageList',
  detail: '/agency/platform/detail',
  insert: '/agency/platform/insert',
  update: '/agency/platform/update',
};

export const InstitutionCount = {
  count: '/count',
  city: '/city',
  day: '/day',
  hour: '/hour',
};

// 渠道面板
export const ChannelPanel = {
  channelPanelList: '/channel/count/list',
  channelPanelDetail: '/channel/count/details',
};

// 结算凭证
export const SettlementVoucher = {
  pageList: '/channel/balance/voucher',
};

// 平台账户
export const PlatformAccount = {
  pageList: '/platform_account/page_list',
  details: '/platform_account/trading_details',
  balance: '/platform_account/balance', // 查询平台账户余额
};

// 平台消耗记录
export const PlatformRecords = {
  pageList: '/platform_consume/daily_page',
  updateSettlement: '/platform_consume/update_settlement',
};

// 平台充值
export const PlatformRecharge = {
  pageList: '/platform_trading/page_list',
  recharge: '/platform_account/recharge',
  dynamicCode: '/platform_account/dynamicCode',
  export: '/platform_trading/export',
};

// 营销产品列表
export const mktProduct = {
  mktProductList: '/mktProduct/list',
  mktProductAdd: '/mktProduct/add',
  mktProductDetail: '/mktProduct/detail',
  mktProductfilAdd: '/mktProduct/filter/add',
  mktProductfilInfo: '/mktProduct/filter/info',
  mktProductUpdate: '/mktProduct/update',
  mktProductChannel: '/mktProduct/channel',
  mktProductBrandSelecte: '/mktProductBrand/selected',
};
// 营销产品数据面板
export const mktProductData = {
  mktProductDataList: '/mktProductData/list',
  mktProductDataIncomeProof: '/mktProductData/incomeProof',
  mktProductDataRecord: '/mktProductData/record',
  mktProductDataExport: '/mktProductData/export',
};
// 营销产品品牌列表
export const mktProductBrandingData = {
  mktProductBrandList: '/mktProductBrand/list',
  mktProductBrandSave: '/mktProductBrand/save',
};
// 营销渠道列表
export const mktChannel = {
  mktChannelList: '/mktChannel/list',
  mktChannelAdd: '/mktChannel/add',
  mktChannelDetail: '/mktChannel/detail',
  mktChannelLandPage: '/mktChannel/landPage',
  mktChannelOperator: '/mktChannel/operator',
  mktChannelfilInfo: '/mktChannel/operator/info',
  mktChannelProduct: '/mktChannel/product',
  mktChannelUpdate: '/mktChannel/update',
  mktChannelGetConfig: '/mktChannel/get_config',
};
// 营销渠道落地页列表
export const mktLandPage = {
  mktLandPageList: '/mktLandPage/list',
  save: '/mktLandPage/add',
  detail: '/mktLandPage/detail',
  mktLandPageRouteAdd: '/mktLandPage/routeAdd',
  mktLandPageRouteList: '/mktLandPage/routeList',
  update: '/mktLandPage/update',
};
// 营销渠道数据面板
export const mktChannelDayCount = {
  list: '/mktChannelDayCount/list',
  export: '/mktChannelDayCount/export',
};
// 网贷业务管理
export const mktBusiness = {
  mktBusinessImpactList: '/mktBusiness/impactList',
  OrderList: '/mktBusiness/orderList',
  export: '/mktBusiness/export',
};
// 开发者工具
export const developmentTool = {
  encrypt: '/encrypt',
  decrypt: '/decrypt',
  encryptStr: '/cleanData/encryptStr', //字符串加密
  decryptStr: '/cleanData/decryptStr', //字符串解密
  encryptJSON: '/cleanData/encryptJSON', //json加密
  decryptJSON: '/cleanData/decryptJSON', //json解密
};
// 营销短信
export const template = {
  templateList: '/recall/template/list',
  templateDetail: '/recall/template/detail',
  templateAdd: '/recall/template/add',
  templateUpdate: '/recall/template/update',
  planList: '/recall/plan/list',
  planDetail: '/recall/plan/detail',
  planAdd: '/recall/plan/add',
  planUpdate: '/recall/plan/update',
  templateQueryAll: '/recall/template/queryAll',
};
