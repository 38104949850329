import logoImg from '@/assets/images/logo.png';
const runType = import.meta.env.APP_FLAG;

let apiPrefixPath = '';
const aesKey = 'gMJmPWJGv/2JbcXCYjHbJQ==';
let SIGNKEY = '';
const logo = logoImg;
let title = '江湖海';
export let ossPath = '';
let documentTitle = '';
let channelDocumentTitle = '';
console.log('runType', runType);
switch (runType) {
  case 'pro-hyt': // hyt线上环境
    apiPrefixPath = 'https://cms.haipush.com/cms/';
    ossPath = 'https://oss.haipush.com/';
    SIGNKEY = 'EDH6sOgEILMSMPkJt78ilfMUpJ5FajXJ';
    title = '乾小花';
    documentTitle = '后台管理系统';
    channelDocumentTitle = '渠道开放平台';
    break;
  case 'pro': // jhh线上环境
    apiPrefixPath = 'https://cms.jhhjk.com/cms/';
    ossPath = 'https://oss.jhhjk.com/';
    SIGNKEY = 'EDH6sOgEILMSMPkJt78ilfMUpJ5FajXJ';
    documentTitle = '后台管理系统';
    channelDocumentTitle = '渠道开放平台';

    break;
  case 'test': // 外网测试环境
    apiPrefixPath = 'https://cms-test.jhhjk.com/cms/';
    ossPath = 'https://oss.jhhjk.com/';
    SIGNKEY = 'EDH6sOgEILMSMPkJt78ilfMUpJ5FajXJ';
    documentTitle = '测试环境-后台管理系统';
    channelDocumentTitle = '测试环境-渠道开放平台';

    break;
  case 'development': // 本地开发
    apiPrefixPath = '/web';
    ossPath = 'https://oss.jhhjk.com/';
    SIGNKEY = 'EDH6sOgEILMSMPkJt78ilfMUpJ5FajXJ';
    documentTitle = '本地-后台管理系统';
    channelDocumentTitle = '本地-渠道开放平台';

    break;
  default: // 默认环境
    apiPrefixPath = 'https://cms-test.jhhjk.com/cms/';
    ossPath = 'https://oss.jhhjk.com/';
    SIGNKEY = 'EDH6sOgEILMSMPkJt78ilfMUpJ5FajXJ';
    documentTitle = '测试环境-后台管理系统';
    channelDocumentTitle = '测试环境-渠道开放平台';

    break;
}
export default {
  apiPrefixPath,
  aesKey,
  SIGNKEY,
  ossPath,
  logo,
  title,
  documentTitle,
  channelDocumentTitle,
};
