import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { ElMessage } from 'element-plus';
import router from '@/router';
import adminConfig from '@/libs/config';
import basics from '@/libs/basics';
import basicsMethods from '@/libs/basicsMethods';
import api from '@/libs/api';
import _ from 'lodash-es';
import CryptoJS from 'crypto-js';
import { mainStore } from '@/store';
import { userType } from '@/enums/common';
const service = axios.create({
  baseURL: api.apiPrefixPath,
  timeout: 1000 * 600,
  headers: {
    'Content-Type': 'application/json;', // application/x-www-form-urlencoded;charset=UTF-8
  },
});
const aesKey = api.aesKey;
const key = CryptoJS.enc.Utf8.parse(aesKey);

function encryptDataWithoutIV(encryData) {
  console.log(encryData);
  // 将数据转换为WordArray格式，如果数据已经是字符串，则使用Utf8编码
  const dataWordArray = CryptoJS.enc.Utf8.parse(JSON.stringify(encryData));
  // 执行AES加密，使用ECB模式，不指定IV
  const encrypted = CryptoJS.AES.encrypt(dataWordArray, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  // 结果转换为16进制以便传输
  const encryptedHex = encrypted.ciphertext.toString(CryptoJS.enc.Hex);

  return encryptedHex;
}

const timestamps = Date.now();

// axios实例拦截请求
service.interceptors.request.use(
  // enc加密
  (config: any) => {
    // 获取后台的动态接口域名
    // const store = mainStore();
    // config.baseURL = store.axiosBaseURL;

    const token = basics.Storage.getSession(adminConfig.store.authorCacheName)?.token || '';
    let str;
    if (!_.isEmpty(config.data) || !_.isEmpty(config.params)) {
      const data = config.data || config.params;
      str = basicsMethods.createRequestSign(data, timestamps, config.method);
    } else {
      str = `${timestamps}${api.SIGNKEY}`;
      config.data = undefined;
    }

    config.headers = {
      ...config.headers,
      token: token,
      sign: CryptoJS.MD5(str).toString().toLowerCase(),
      timestamps: timestamps.toString(),
      os: 'h5',
      osType: 'h5',
      businessType: 'admin',
      // bodyCode: store.bodyCode,
    };
    if (config.method === 'post_form') {
      config.method = 'post';
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    }

    if (config.method === 'post') {
      console.log(config);
      const encryData = config.data;
      console.log(encryData);
      //判断post请求的body是否为空，不为空就加密，为空就传递一个空对象
      if (encryData) {
        const encryptedData = encryptDataWithoutIV(encryData);
        config.data = {
          data: encryptedData,
          hideFlag: 'cms_hide_data', //是否加密
        };
      } else {
        config.data = {};
      }
    }
    /*
     * 因为axios中get会自动encodeURIComponent params参数
     * 空格等字符转换有出入 在公共initPage组件 将列表请求接口的参数拼接在 URL上
     * 所以这里需要将params清空
     *  */
    if (config.method === 'get' && config.url.indexOf('?') > -1) config.params = undefined;
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// axios实例拦截响应
service.interceptors.response.use(
  // 2xx时触发
  (response: AxiosResponse) => {
    // response.data就是后端返回的数据，结构根据你们的约定来定义
    // 响应解密
    if (response?.config?.responseType == 'blob') {
      return response;
    } else {
      const resData: any = response.data;
      // console.log(resData, '----响应数据', response);
      const type = basics.Storage.getSession(adminConfig.store.authorCacheName)?.type || '';

      const { code, message }: { code: number; message: string } = resData;
      if (code !== adminConfig.requestParameters.request_SUCCESS) {
        switch (code) {
          case adminConfig.requestParameters.request_FAIL:
          case adminConfig.requestParameters.request_SIGN_ERROR:
          case adminConfig.requestParameters.request_AUTH_ERROR:
          case adminConfig.requestParameters.request_RUN_EXCEPTION:
          case adminConfig.requestParameters.request_BLACK:
          case adminConfig.requestParameters.request_NOT_PERMISSION:
          case adminConfig.requestParameters.request_TIMEOUT:
          case adminConfig.requestParameters.request_VISIT_OUT:
          case adminConfig.requestParameters.request_NOT_FOUND:
            ElMessage.error(message);
            break;
          case adminConfig.requestParameters.request_TOKEN_ERROR:
            ElMessage.error('登录已失效，请重新登录!');
            localStorage.clear();
            sessionStorage.clear();
            if (type === userType.channelUser) {
              router.replace('/channelLogin');
            } else {
              router.replace('/login');
            }
            setTimeout(() => {
              window.location.reload();
            }, 100);
            break;
        }
        return Promise.reject(resData);
      } else {
        return resData;
      }
    }
  },
  // 非2xx时触发
  (error: AxiosError) => {
    ElMessage.error('网络错误');
    return Promise.reject(error);
  }
);

export type { AxiosResponse, AxiosRequestConfig };

export default service;
