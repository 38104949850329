import md5 from 'js-md5';
import { tsTypes } from '@/libs/interfaceType';
import api from '@/libs/api';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CryptoJS from 'crypto-js';
import regular from './regular';

const basicsMethods = {
  // 统一处理formlist
  unifiedListFindValue(list: Array<tsTypes.ParamsUnKnown>, value: number | string, key: string) {
    return (
      list.find((item) => {
        return item[key] === value;
      }) || { text: '-' }
    );
  },
  // 签名
  createRequestSign(
    data: tsTypes.ParamsUnKnown = {},
    timestamps?: number | string,
    method?: string
  ): string {
    const keyList = Object.keys(data);
    let urlParams = '';
    if (method === 'get') {
      for (const key of keyList) {
        urlParams +=
          keyList[keyList.length - 1] !== key
            ? `${key}=${encodeURI(<string>data[key])}&`
            : `${key}=${encodeURI(<string>data[key])}`;
      }
    } else {
      urlParams = JSON.stringify(data);
    }
    urlParams += `${timestamps}${api.SIGNKEY}`;
    // console.log('--------urlParams-->', urlParams);
    return urlParams;
  },

  mathMethod: {
    // 乘法
    multiply(arg1: number, arg2: number) {
      let m = 0;
      try {
        const arg1Fractional = String(arg1).split('.')[1];
        const arg2Fractional = String(arg2).split('.')[1];
        m += (arg1Fractional && arg1Fractional.length) || 0;
        m += (arg2Fractional && arg2Fractional.length) || 0;
      } catch (e) {
        return NaN;
      }
      return (
        (Number(String(arg1).replace('.', '')) * Number(String(arg2).replace('.', ''))) /
        Math.pow(10, m)
      );
    },
    // 除法
    division(arg1: number, arg2: number) {
      try {
        const arg1Fractional = String(arg1).split('.')[1];
        const arg2Fractional = String(arg2).split('.')[1];
        const arg1Length = (arg1Fractional && arg1Fractional.length) || 0;
        const arg2Length = (arg2Fractional && arg2Fractional.length) || 0;
        const differenceMultiple = Math.pow(10, arg2Length - arg1Length);
        return this.multiply(
          Number(String(arg1).replace('.', '')) / Number(String(arg2).replace('.', '')),
          differenceMultiple
        );
      } catch (e) {
        return NaN;
      }
    },
    // 加法
    addition(arg1: number, arg2: number) {
      try {
        const arg1Fractional = String(arg1).split('.')[1];
        const arg2Fractional = String(arg2).split('.')[1];
        const arg1Length = (arg1Fractional && arg1Fractional.length) || 0;
        const arg2Length = (arg2Fractional && arg2Fractional.length) || 0;
        const expandedMultiplier = Math.pow(10, Math.max(arg1Length, arg2Length));
        return (
          (this.multiply(arg1, expandedMultiplier) + this.multiply(arg2, expandedMultiplier)) /
          expandedMultiplier
        );
      } catch (e) {
        return NaN;
      }
    },
    // 减法
    subtraction(arg1: number, arg2: number) {
      try {
        const arg1Fractional = String(arg1).split('.')[1];
        const arg2Fractional = String(arg2).split('.')[1];
        const arg1Length = (arg1Fractional && arg1Fractional.length) || 0;
        const arg2Length = (arg2Fractional && arg2Fractional.length) || 0;
        const expandedMultiplier = Math.pow(10, Math.max(arg1Length, arg2Length));
        return (
          (this.multiply(arg1, expandedMultiplier) - this.multiply(arg2, expandedMultiplier)) /
          expandedMultiplier
        );
      } catch (e) {
        return NaN;
      }
    },
  },

  // 将数值变为tree的结构
  treeDataClean: (items: Array<tsTypes.objAndAny>, fatherId: 0) => {
    const result: any[] = []; // 存放结果集
    const itemMap: tsTypes.objAndAny = {}; //
    for (const item of items) {
      const id = item.id;
      const pid = item.pid;

      if (!itemMap[id]) {
        itemMap[id] = {
          children: [],
        };
      }

      itemMap[id] = {
        ...item,
        children: itemMap[id]['children'],
      };

      const treeItem: any = itemMap[id];

      if (pid === fatherId) {
        result.push(treeItem);
      } else {
        if (!itemMap[pid]) {
          itemMap[pid] = {
            children: [],
          };
        }
        itemMap[pid].children.push(treeItem);
      }
    }
    return result;
  },

  // 数字金额转换中文大写
  numberToChineseAmount: (num: number): string | boolean => {
    const res = regular('onlyNumberOrTwoPointNumber').regular.test(num);
    if (!res) {
      return false;
    }
    const numStr: string = num.toString();
    const numArr: string[] = numStr.split('.');
    const integerPart: string = numArr[0];
    const decimalPart: string = numArr[1] || '';

    const unit: string[] = [
      '',
      '十',
      '百',
      '千',
      '万',
      '十',
      '百',
      '千',
      '亿',
      '十',
      '百',
      '千',
      '万',
    ];
    const chnNum: string[] = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];

    let result = '';
    const length: number = integerPart.length;
    for (let i = 0; i < length; i++) {
      if (integerPart[i] === '0') {
        if (i === length - 1 || integerPart[i + 1] === '0') {
          continue;
        } else {
          result += chnNum[0];
        }
      } else {
        result += chnNum[parseInt(integerPart[i])] + unit[length - i - 1];
      }
    }

    if (decimalPart) {
      result += '元';
      for (let i = 0; i < decimalPart.length; i++) {
        if (i === 0) {
          result += chnNum[parseInt(decimalPart[i])] + '角';
        } else if (i === 1) {
          result += chnNum[parseInt(decimalPart[i])] + '分';
        }
      }
    } else {
      result += '元整';
    }

    return result;
  },

  //将对象中的属性处理为对应类型的空值
  resetObjectProperties: function (obj: tsTypes.objAndAny, defaultValue?: any) {
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          basicsMethods.resetObjectProperties(value, defaultValue); // 递归调用
        } else {
          // 根据类型设定默认值，也可以传入defaultValue来自定义默认值
          let resetValue = defaultValue;
          if (typeof value === 'string') {
            resetValue = '';
          } else if (typeof value === 'number') {
            resetValue = 0;
          } else if (typeof value === 'boolean') {
            resetValue = false;
          } else if (Array.isArray(value)) {
            resetValue = [];
          }
          // 如果没有提供defaultValue或者有特定类型的处理逻辑，使用resetValue
          obj[key] = resetValue ?? value; // 使用ES6的空值合并操作符，如果resetValue为undefined，则保留原值
        }
      }
    }
  },
};

export default basicsMethods;
