import { defineStore } from 'pinia';
import basics from '@/libs/basics';
import api from '@/libs/api';

export const mainStore = defineStore('main', {
  state: () => {
    return {
      init: false, // 加载状态
      menuList: [] as any[], // 菜单
      breadCrumbList: [], // 顶部 面包屑 导航栏

      layoutWidth: 0, // 页面除菜单的宽度

      /*
       * 全局图片预览
       * */
      previewPictureModalFlag: false,
      previewPictureModalImgSrc: [],

      /*
       * 全局form表单显隐
       * */
      formModalFlag: false,
      /*
       * 详情写成对象 方便部分详情的key可以自定义
       * */
      formModalRequestInfo: {},

      axiosBaseURL: '',
      bodyCode: '',
      urlMap: {},
    };
  },

  getters: {
    getLayoutWidth(state) {
      return state.layoutWidth as number;
    },
  },

  actions: {
    setBreadCrumb(to: any) {
      const matched = to.matched;
      const metaArr = matched.filter((item: any) => {
        return item.meta !== undefined;
      });
      const arr: Array<any> = [];
      metaArr.forEach((item: any) => {
        arr.push(item.meta.title);
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.breadCrumbList = arr;
    },

    previewPictureModalClick(to: boolean, url = '') {
      const fUrl = url.indexOf('http') > -1 ? url : api.ossPath + url;
      this.previewPictureModalFlag = to;
      this.previewPictureModalImgSrc = Object.assign([], [fUrl]); // 预览接收图片参数的是 Array
    },
  },
});
